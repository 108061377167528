import React from "react";
import "./App.css";
import Hero from "./components/Hero";
import Compisition from "./components/Compisition";
import Maps from "./components/Maps";
import Property from "./components/Property";

import Footer from "./components/Footer";
import Carousel from "./components/Carousel";
// import Navbar from "./components/Navbar";

function App() {
  return (
    <div class="hero scroll-smooth">
     
      <Hero />
  
      <Compisition />
      <Property />
      <Maps />
    
      <Carousel />
      <Footer />
    </div>
  );
}
export default App;
