import React from "react";
import img from "../pic/nature-3.jpg";
import img1 from "../pic/nature-1.jpg";
import img2 from "../pic/IMG-20230116-WA0024.jpg";
const Compisition = () => {
  return (
    <section id="Home">
   <div className="justify-center   ">
        <div className="p-14">
          <div className="grid grid-cols-2 grid-flow-col gap-4  ">
            <div className="col-span-2 md:col-span-1  text-justify flex-wrap items-center mt-14 ">
              <p className="text-justify md:m-12 " align="center">
                <div className="p-14 -ml-12">
                  <h2 className="uppercase  transparent tracking-wide text-3xl text-black-400 inline-block ">
                  
                    NATURE PARK
                  </h2>
                </div>
                Large Rooms and Spacious Living Rooms with Pantry and Tea Maker.
                Ambient Lighting ,designer interiors and Luxurious Wash rooms
                with Hot water Facility. Open Terreces and Balconies for better
                Views. Smart TV and WiFi. Next to Wynad Forest for Real Wild
                Life Experience and Amazing Views. Kitchen equipped with Heavy
                Cooking Range and BBQ. Banquet Area for 50 Persons Dining and
                Get together. Natural Pool and Kids Play Area. Free By cycles
                for Morning Ride. Check out time 24 hours.
              </p>
            </div>
            {/* for desktop  */}

            <div className="hidden md:flex relative col-span-2  md:col-span-1 items-end ">
              <div className="absolute left-2 top-20 w-3/5    hover:scale-110 duration-200 hover:z-10">
                <img
                  src={img}
                  className="  shadow-lg rounded-lg h-[13rem]"
                  alt="pic1"
                />{" "}
              </div>

              <div className="absolute left-64  w-3/5 top-28  hover:scale-110 duration-200 hover:z-10 ">
                <img
                  src={img1}
                  className=" shadow-lg rounded-lg  h-[13rem]"
                  alt="pic2"
                />{" "}
              </div>

              <div className="absolute right-40 top-64 w-3/5   hover:scale-110 duration-200 hover:z-10 ">
                <img
                  src={img2}
                  className=" shadow-lg rounded-lg h-[13rem]"
                  alt="pic3"
                />
              </div>
            </div>
          </div>
          {/* phone */}
         
          <div className="grid grid-cols pt-9 ">
            <div className="md:hidden relative ">
              <div className=" col-span-2 flex   ">
                <div className=" absolute -left-12 p-2 top-22 w-3/5  ">
                  <img
                    src={img}
                    className="  shadow-lg rounded-lg"
                    alt="pic1m"
                  />
                </div>

                <div className="absolute  left-36 p-2 w-3/5  ">
                  <img
                    src={img1}
                    className="  shadow-lg rounded-lg"
                    alt="pic2m"
                  />
                </div>

                <div className="  absolute p-2 -top-2 left-12 w-3/5">
                  <img
                    src={img2}
                    className="  shadow-lg rounded-lg"
                    alt="pic3m"
                  />
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>
  );
};

export default Compisition;
