import React from "react";
import { FaWifi } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { FaSwimmer } from "react-icons/fa";
import { FaParking } from "react-icons/fa";

const Property = () => {
  return (
    <div className="mt-10  ">
      <div class="flex flex-col md:flex-row gap-3 justify-center p-6 text-green-400   ">
        <div className="flex flex-col bg-glass rounded-lg   border border-glass-border  w-full items-center  p-3 backdrop-blur-lg ">
          <div className="align-center">
            <FaWifi className=" text-8xl text-green-400 inline-block  to-transparent h-28" />
          </div>
          <div>
            <h2 clasName="text-center px-2 pb-5 align">Fast Internet</h2>
          </div>
        </div>
        <div className="flex flex-col bg-glass rounded-lg  border border-glass-border backdrop-opacity-50 w-full items-center  p-3 backdrop-blur ">
          <FaUserAlt className=" text-8xl  text-green-400 inline-block  to-transparent  h-28 " />
          <div>
            <h2 class="text-center px-2 pb-5 align">Private Territory</h2>
          </div>
        </div>
        <div className="flex flex-col bg-glass rounded-lg  border border-glass-border backdrop-opacity-50 w-full items-center  p-3 backdrop-blur ">
          <FaSwimmer className="  text-8xl text-green-400 inline-block  to-transparent  h-28 " />
          <div>
            <h2 class="text-center px-2 pb-5 align">Swimming Pool</h2>
          </div>
        </div>
        <div className="flex flex-col bg-glass rounded-lg  border border-glass-border backdrop-opacity-50 w-full items-center  p-3 backdrop-blur ">
          <FaParking className="  text-8xl  text-green-400 inline-block  to-transparent  h-28 " />
          <div>
            <h2 class="text-center px-2 pb-5 align">car Parking</h2>
          </div>
        </div>
        {/* phone */}
      </div>
    </div>
  );
};

export default Property;
