import { useState } from "react";
import { close, menu } from "../assets";



const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="flex items-center justify-end py-8">
      
    <div>
      <ul className="md:flex gap-8 p-7 uppercase text-xl md:mr-28 hidden cursor-pointer scroll-smooth text-white ">
        <li className=" hover:text-black hover:underline duration-200  ">
          <a href="#Home">Home</a>
        </li>
        <li className=" hover:text-black hover:underline duration-200 ">
        <a href="#Gallery">Gallery</a>
        </li>
        <li className=" hover:text-black hover:underline duration-200 ">
        <a href="#Contact">Contact</a>
        </li>
      </ul>
    </div>

    {/* m obile*/}
    <div className="sm:hidden flex flex-1 justify-end items-center">
      <img
        src={toggle ? close : menu}
        alt="ham"
        className="  mr-[22px] w-[28px] h-[28px] object-contain"
        onClick={() => setToggle((prev) => !prev)}
      />
      <div
        className={`${
          toggle ? "flex" : "hidden"
        } p-6 bg-gray-300 absolute top-16 right-0 mx-4 my-2 min-w-[10px] rounded-xl sidebar`}
      >
        <ul className="md:flex gap-8 p-7 uppercase text-xl md:mr-28  cursor-pointer scroll-smooth text-black ">
        <li className=" hover:underline duration-200  ">
          <a href="#Home">Home</a>
        </li>
        <li className="  hover:underline duration-200 ">
        <a href="#Gallery">Gallery</a>
        </li>
        <li className=" hover:underline duration-200 ">
        <a href="#Contact">Contact</a>
        </li>
      </ul>
        
      </div>
    </div>
  </div>

  );
};

export default Navbar;
// bg-gradient-to-r from-gray-700