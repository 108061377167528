import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import img3 from "../img1/IMG-20230116-WA0024.jpg";
import img4 from "../img/IMG-20230113-WA0015.jpg";
import img2 from "../img/IMG-20230113-WA0019.jpg";
import img from "../pic/IMG_8603.JPG";


import img10 from "../pic/IMG_8591.JPG";
import img6 from "../img1/IMG-20230113-WA0016.jpg";
import img7 from "../img1/IMG-20230113-WA0012.jpg";
import img8 from "../img1/IMG-20230113-WA0021.jpg";
import img9 from "../pic/nature-5.jpg";
import img5 from "../pic/nature-6.jpeg";
import img11 from "../pic/IMG_8580.JPG";
import img12 from "../pic/IMG_8592.JPG";


const sliderData = [
  img3,
  
  img2,
  img,
  img4,
  img5,
  img12,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11
];

const Carousel = () => {
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    console.log("handleResize", window.innerWidth);
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);
  console.log("width", dimensions);
  return (
    <div className="p-10  block  md:flex-row " section id="Gallery">
      <Swiper
        modules={[Navigation]}
        spaceBetween={30}
        slidesPerView={
          dimensions.width < 640 ? 1 : dimensions.width < 1024 ? 3 : 5
        }
        navigation={dimensions.width <= 768 ? false : true}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        {sliderData.map((sliderData, index) => (
          <SwiperSlide key={index}>
            <img
              src={sliderData}
              className="rounded-lg shadow-xl  md:hover:scale-110  hover:z-10 duration-100 "
              alt="/"
              style={{ width: "300px", height: "250px" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default Carousel;
