import React from "react";
import logo from "../pic/LOGO-NATUREPARK-1.svg"
import instagram from "../img/1384031.png";
import facebook from "../img/20673.png";
import logomark from "../img/LogoMakr-3pqg3S.png";

const Footer = () => {
  return (
    <section id="Contact">
      <div className="hidden md:flex flex-col items-center justify-between md:flex-row border-1    ">
        <div className="mr-5">
          <img src={logo} alt="footer_logo" className="w-40 md:w-80" />
        </div>
        <div className="flex flex-col items-center justify-center gap-2 mt-4 md:justify-start md:items-start md:mt-0 ">
          <h4 className="uppercase">Follow Us On</h4>
          <div className="flex flex-col justify-center gap-2">
            <div className="flex justify-center gap-1 md:justify-start">
              <a href="https://instagram.com/natureparkresorts?igshid=Yzg5MTU1MDY=">
                <img src={instagram} alt="instagram" width={35} />
              </a>

              <img src={facebook} alt="facebook" width={35} />
            </div>

            <div className="hidden md:flex   md:justify-center ">
              <p>
                &#169; Naturepark 2023 <br />
                All rights reserved
              </p>
            </div>
          </div>
        </div>
        <div className=" hidden md:flex flex-col justify-start gap-2 ">
          <h4 className="uppercase">Nature PArk</h4>
          <div className="flex flex-col gap-1 font-normal">
            <p>
              Behind Lakkidi UP School, Lakkidi, <br />
              Wayanad, Kerala, India. 6733576 <br />
              <br />
              <div className="font-normal duration-100 cursor-pointer mt-2">
              <a href="tel:+91 9633390838"> +91 9633 390 838</a>
              </div>
             
              <span className="font-normal duration-100 cursor-pointer">
                <a href="mailto: mail@naturepark.in">mail@naturepark.in</a> 
                
              </span>
            </p>
          </div>
        </div>
        <div>
          <div>
            <img src={logomark} alt="logomark" />
          </div>
        </div>
      </div>
      {/* phone */}

      <div
        align="center "
        className=" md:hidden flex flex-col items-center justify-between md:flex-row border-1 gap-4"
      >
        <div className="mr-5">
          <img src={logo} alt="footer_logo" className="w-40 md:w-80" />
        </div>
        <div className="flex flex-col items-center justify-center gap-8  md:justify-start md:items-start md:mt-0 ">
          <h4 className="uppercase ">Follow Us On</h4>
          <div className="flex flex-col justify-center  ">
            <div className="flex justify-center gap-4 md:justify-start">
              <a href="https://instagram.com/natureparkresorts?igshid=Yzg5MTU1MDY=">
                <img src={instagram} alt="instagram" width={35} />
              </a>

              <img src={facebook} alt="facebook" width={35} />
            </div>
          </div>
        </div>
        <div className="  flex-col justify-start  ">
          <h4 className="uppercase">Nature PArk</h4>
          <div className="flex flex-col gap-1 font-normal">
            <p>
              Behind Lakkidi UP School, Lakkidi, <br />
              Wayanad, Kerala, India. 6733576 <br />
              <br />
              <span className="font-normal duration-100 cursor-pointer">
              <div className="font-normal duration-100 cursor-pointer mt-2">
              <a href="tel:+91 9633390838"> +91 9633 390 838</a>
              </div>
                <a href="mailto: mail@naturepark.in">mail@naturepark.in</a>
              </span>
            </p>
          </div>
        </div>
        <div>
          <div>
            <img src={logomark} alt="logomark" />
          </div>
        </div>
        <div className=" md:hidden flex justify-center mb-3 ">
          <p>&#169; Naturepark 2023 All rights reserved</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
