import React from "react";

const Maps = () => {
  return (
    <>
      <div className="flex justify-center items-center ">
        <iframe
          width="100%"
          height="500px"
          title="map"
          className="rounded-lg   shadow-transparent"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250316.60598943176!2d75.76943709023021!3d11.393235636771388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba66dfa25dd5d5d%3A0x298710c872e0cf70!2sNature%20Park%20Resorts!5e0!3m2!1sen!2sin!4v1674134089346!5m2!1sen!2sin"
        />
      </div>
    </>
  );
};

export default Maps;



