import React from "react";
import logo from "../img/LOGO-NATUREPARK-white-1.svg";

import Navbar from "./Navbar";


const Hero = () => {
  
  return (
    <div>
       
      <div className="hero-1 h-screen  justify-center   ">
     <Navbar/>
     

        <div className="">
        <div className=" md:flex justify-center  items-center  ">
            <img
              src={logo}
              alt="img"
              className=" w-96 flex justify-center   sm:translate-y-1/2 items-center  sm:-mb-28  md:-mt-36 "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
